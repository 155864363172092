import { Text, Box, Button, Input, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { ConfirmationResult, onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import isMobilePhone from 'validator/lib/isMobilePhone';
import { firebaseUserState } from "../atoms/firebaseUserState";
import { auth } from "../firebaseConfig";
import getUserDoc from "../services/getUserDoc";
import login from "../services/login";
import signUp from "../services/signUp";
import VerifyPhone from "./VerifyPhone";

export default function Login(props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [phone, setPhone] = useState(undefined as string | undefined)
    const [phoneError, setPhoneError] = useState(undefined as string | undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [firebaseUser, setFirebaseUser] = useRecoilState(firebaseUserState)
    const [confirmationResult, setConfirmationResult] = useState(undefined as ConfirmationResult | undefined)
    const navigator = useNavigate();

    const _login = async () => {
        setIsLoading(true)
        const _phoneError: string | undefined = !isMobilePhone(phone ?? '', 'en-US') ? 'Please enter a valid phone number!' : undefined
        setPhoneError(_phoneError)
        if(!_phoneError) {
            if(phone) {
                // check if user exists for this phone and return error
                const user = await getUserDoc(phone);
                if (!user) {
                    setPhoneError("Dewey doesn't recognize this phone number. Have you signed up yet?")
                    setIsLoading(false);
                    return;
                }
                const _confirmationResult = await login(phone);
                if(_confirmationResult) {
                    setShowConfirm(true)
                    setConfirmationResult(_confirmationResult)
                }
            }
        }
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setFirebaseUser(user)
            console.log(`User: ${user}`)
            if(user) {
              navigator('/dashboard')
            }
          })
      
        setIsLoading(false);
        return () => unsubscribe();
    }

    return (
        <Box>
            <Text display={{ base: 'flex', md: 'none'}} onClick={onOpen}>Sign In</Text>
            <Button display={{ base: 'none', md: 'flex'}} marginRight={[0, '0.5rem']} variant={'ghost'} onClick={onOpen}><span style={{textUnderlineOffset: 5, textDecoration: 'underline'}}>Sign In</span></Button>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent padding={'1rem'}>
                    <ModalHeader>Login</ModalHeader>
                    <ModalBody>
                        {
                            showConfirm ? <VerifyPhone confirmationResult={confirmationResult} />
                            : 
                            <Box>
                                <Input marginTop={'1rem'} 
                                    placeholder="Phone Number" 
                                    type={'number'}
                                    value={phone}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setPhone(event.target.value);
                                }}/>
                                {phoneError ? <Text textColor={'red'}>{phoneError}</Text> : <Box />}
                                <Button id='login-button' isLoading={isLoading} marginTop={'1rem'} onClick={() => {_login()}}>Login</Button>
                            </Box>
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}