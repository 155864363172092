import { User } from "firebase/auth";
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from "../firebaseConfig";

export default async function createUserDoc(user: User, name: String) {
    //check if user doc exists
    const docRef = doc(db, 'users', user.uid)
    const docSnap = await getDoc(docRef)
    if(!docSnap.exists()) {
        const wakeUpTime = new Date();
        const bedtime = new Date(wakeUpTime.getTime());
        wakeUpTime.setHours(7)
        wakeUpTime.setMinutes(0)
        bedtime.setHours(21)
        bedtime.setMinutes(0)

        setDoc(docRef, {
            id: docRef.id,
            name: name,
            phone: user.phoneNumber,
            hourCadence: 24,
            tzOffset: (new Date).getTimezoneOffset() * (-1),
            wakeUpTime: wakeUpTime,
            bedtime: bedtime,
            lastReceivedFromUser: new Date(Date.now()),
            lastSentToUser: new Date(Date.now()),
            lastReminded: new Date(Date.now()),
            active: true,
        })
    }
}