import { Box, Center, Flex, Heading, Text } from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

export default function Learning() {
    return (
        <Box width={'100vh'} paddingLeft={'2rem'} paddingRight={'2rem'} marginBottom={'2rem'} textAlign={'center'}>
            <Heading margin={'1.5rem'}>What Dewey's Learning Next</Heading>
            <Center border={'2px'} borderRadius={'15px'} flexDir={'column'}>
                <Flex alignItems={'center'} margin={'1rem'}>
                    <Box marginRight={'2rem'}>
                        <FaCheckCircle size={'3rem'} color={'#58D68D'} />
                    </Box>
                    <Box>
                        <Text>Learn to remind you of your tasks!</Text>
                    </Box>
                </Flex>
                <Flex alignItems={'center'} margin={'1rem'} >
                    <Box marginRight={'2rem'}>
                        <FaCheckCircle size={'3rem'} color={'#58D68D'} />
                    </Box>
                    <Box>
                        <Text>Learn to remember specific reminder times!</Text>
                    </Box>
                </Flex>
                <Flex alignItems={'center'} margin={'1rem'}>
                    <Box marginRight={'2rem'}>
                        <FaCheckCircle size={'3rem'} color={'#7b7b7b65'} />
                    </Box>
                    <Box>
                        <Text>Learn to remember repeating reminders!</Text>
                    </Box>
                </Flex>
                <Flex alignItems={'center'} margin={'1rem'}>
                    <Box marginRight={'2rem'}>
                        <FaCheckCircle size={'3rem'} color={'#7b7b7b65'} />
                    </Box>
                    <Box>
                        <Text>Learn to let you customize me more!</Text>
                    </Box>
                </Flex>
                <Flex alignItems={'center'} margin={'1rem'}>
                    <Box marginRight={'2rem'}>
                        <FaCheckCircle size={'3rem'} color={'#7b7b7b65'} />
                    </Box>
                    <Box>
                        <Text>Learn to answer more advaned questions!</Text>
                    </Box>
                </Flex>
                <Flex alignItems={'center'} margin={'1rem'}>
                    <Box marginRight={'2rem'}>
                        <FaCheckCircle size={'3rem'} color={'#7b7b7b65'} />
                    </Box>
                    <Box>
                        <Text>Learn what you want me to do next!</Text>
                    </Box>
                </Flex>
            </Center>
        </Box>
    );
}