import { Box, Heading, Text, Center } from "@chakra-ui/react";
import { useEffect } from "react";



export default function TermsAndConditions() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    return (
        <Center flexDir={'column'}>
            <Box width={['90vw', '90vw', '80vw', '60vw']} textAlign={'left'} alignSelf={'center'}>
                <Heading marginTop={'2rem'}>Terms and Conditions</Heading>
                {
                    //<div name="termly-embed" data-id="b61598e0-2b66-49ec-9e29-a2da50b72c6a" data-type="iframe"></div>
                }
                <a href="https://app.termly.io/document/terms-of-use-for-website/b61598e0-2b66-49ec-9e29-a2da50b72c6a" target={"_blank"} style={{color: 'lightblue'}}><u>Click here to view Terms and Conditions</u></a>
            </Box>
            <Text marginTop={'4rem'} marginBottom={'2rem'} fontSize={'0.65rem'}>Created by Kyle Banta<br/>Contact Me @ kbantadevelopment@gmail.com</Text>
        </Center>
    )
}