import { Box, Button, Input } from "@chakra-ui/react";
import { verify } from "crypto";
import { logEvent } from "firebase/analytics";
import { ConfirmationResult } from "firebase/auth";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { firebaseUserState } from "../atoms/firebaseUserState";
import { analytics } from "../firebaseConfig";
import createUserDoc from "../services/createUserDoc";

export default function VerifyPhone(props: any) {
    const confirmationResult: ConfirmationResult = props.confirmationResult;
    const [firebaseUser, setFirebaseUser] = useRecoilState(firebaseUserState)
    const [code, setCode] = useState('')

    const verify = async () => {
        console.log('CODE: ', code)
        // send to other function to verify and return whether successful or not
        let result;
        try {
            result = await confirmationResult.confirm(code)
            // create firestore doc
            if(result.user) {
                createUserDoc(result.user, props.name)
            }
            setFirebaseUser(result.user)
            logEvent(analytics, 'verify_phone_number', { phone: result.user.phoneNumber});
        } catch (e) {
            return false
        }
        console.log('Result: ', result)
        return true
    }
    return (
        <Box>
            <Input placeholder="Verify Code" value={code} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCode(event.target.value)
            }}/>
            <Button marginTop={'1rem'} onClick={verify}>Verify</Button>
        </Box>
    )
}