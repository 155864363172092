import { User } from 'firebase/auth';
import { doc, DocumentSnapshot, getDoc } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { DeweyUser } from '../models/DeweyUser';

export default function useUserData(user: User | null) {
  const [userData, setUserData] = useState(null as DeweyUser | null);

  useEffect(() => {
    if(!user) return;

    getDoc(doc(db, 'users', user.uid)).then((docSnap) => {
        setUserData({
            name: docSnap.data()?.name, 
            phone: docSnap.data()?.phone,
            id: docSnap.id,
            lastReceivedFromUser: docSnap.data()?.lastReceivedFromUser.toDate(),
            lastSentToUser: docSnap.data()?.lastSentToUser.toDate(),
            lastReminded: docSnap.data()?.lastReminded.toDate(),
            hourCadence: docSnap.data()?.hourCadence,
            tzOffset: docSnap.data()?.tzOffset,
            wakeUpTime: docSnap.data()?.wakeUpTime.toDate(),
            bedtime: docSnap.data()?.bedtime.toDate(),
            sendOnWeekends: docSnap.data()?.sendOnWeekends,
            active: docSnap.data()?.active,
        } as DeweyUser)
    }).catch((e) => {
        console.log('E: ', e)
    });
  });

  return userData;
}