import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading } from "@chakra-ui/react";

export default function FAQ() {
    return (
        <Box>
            <Heading marginBottom={'2rem'}>Frequently Asked Questions</Heading>
            <Accordion allowToggle>
                <Box border={'5px #FFFFFF'}>
                    <AccordionItem>

                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                <Heading size={'md'}>What is Dewey?</Heading>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                            Dewey is an AI accountability buddy that helps you manage your to-do list and develop new habits (or break old ones). It does this by sending you text message reminders throughout the day.
                            </AccordionPanel>
                    </AccordionItem>
                </Box> 

                <Box border={'5px #FFFFFF'}>
                    <AccordionItem>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                <Heading size={'md'}>What can Dewey do?</Heading>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                            In addition to helping you manage your to-do list and develop new habits, Dewey can also answer simple questions like getting recipes for you or looking things up from history.
                            </AccordionPanel>
                    </AccordionItem>
                </Box>
                <Box border={'5px #FFFFFF'}>
                    <AccordionItem>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                <Heading size={'md'}>How do I get started with Dewey?</Heading>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                            To get started with Dewey, simply sign up for an account and provide your mobile phone number. Once you've done this, you can start adding tasks to your to-do list and talking to Dewey.
                            </AccordionPanel>
                    </AccordionItem>
                </Box>
                <Box border={'5px #FFFFFF'}>
                    <AccordionItem>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                <Heading size={'md'}>How much does Dewey cost?</Heading>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                            Dewey is free for up to 3 open tasks at a time, though we plan to offer a "Best Friends" plan with unlimited tasks and other perks.
                            </AccordionPanel>
                    </AccordionItem>
                </Box>
                <Box border={'5px #FFFFFF'}>
                    <AccordionItem>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                <Heading size={'md'}>How often will I receive reminders from Dewey?</Heading>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                            You'll receive reminders from Dewey as often as you like. You can change your settings by texting Dewey to change how often he reminds you. By default it will remind you every three hours between 7am and 9pm in the timezone you signed up in.
                            </AccordionPanel>
                    </AccordionItem>
                </Box>
                <Box border={'5px #FFFFFF'}>
                    <AccordionItem>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                <Heading size={'md'}>I'm having trouble with Dewey. What should I do?</Heading>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                            If you're having trouble with Dewey, the first thing you should do is check the FAQs to see if your question has already been answered. If you can't find what you're looking for, you can contact Dewey customer support by emailing kbantadevelopment@gmail.com.
                            </AccordionPanel>
                    </AccordionItem>
                </Box>
                <Box border={'5px #FFFFFF'}>
                    <AccordionItem>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                <Heading size={'md'}>What if I have more questions about Dewey?</Heading>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                            If you have more questions about Dewey, please feel free to email kbantadevelopment@gmail and I'll be happy to help.
                            </AccordionPanel>
                    </AccordionItem>
                </Box> 
            </Accordion>
        </Box>
    );
}