import { Text, Box, Button, Input, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { logEvent } from "firebase/analytics";
import { ConfirmationResult } from "firebase/auth";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import isMobilePhone from 'validator/lib/isMobilePhone';
import { analytics } from "../firebaseConfig";
import signUp from "../services/signUp";
import VerifyPhone from "./VerifyPhone";

export default function SignUp(props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [name, setName] = useState(undefined as string | undefined)
    const [nameError, setNameError] = useState(undefined as string | undefined)
    const [phone, setPhone] = useState(undefined as string | undefined)
    const [phoneError, setPhoneError] = useState(undefined as string | undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [confirmationResult, setConfirmationResult] = useState(undefined as ConfirmationResult | undefined);
    const navigator = useNavigate();

    const _signUp = async () => {
        logEvent(analytics, 'sign_up', { position: props.position });
        setIsLoading(true)
        const _nameError: string | undefined = !name || name.length < 1 ? 'Please enter your name!' : undefined;
        const _phoneError: string | undefined = !isMobilePhone(phone ?? '', 'en-US') ? 'Please enter a valid phone number!' : undefined
        setNameError(_nameError)
        setPhoneError(_phoneError)

        if(!_nameError && !_phoneError) {
            if(name && phone) {
                const _confirmationResult = await signUp(name, phone);
                if(_confirmationResult) {
                    setShowConfirm(true)
                    setConfirmationResult(_confirmationResult)
                }
            }
        }
        setIsLoading(false)
    }

    return (
        <Box>
            <Text display={props.menu ? { base: 'flex', md: 'none'} : 'none'} onClick={onOpen}>{props.buttonText}</Text>
            <Button display={props.menu ? { base: 'none', md: 'flex'} : 'flex'} 
                bgColor={props.buttonColor ?? 'white'}
                color={props.buttonTextColor ?? 'black'}
                size={props.big ? 'lg' : 'md'} 
                onClick={onOpen}
                fontSize={props.big ? '2rem' : '1rem'}
                marginTop={props.menu ? '0' : '2rem'}
                >{props.buttonText}</Button>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent padding={'1rem'}>
                    <ModalHeader>{props.headerText}</ModalHeader>
                    <ModalBody>
                        {
                            showConfirm ? <VerifyPhone confirmationResult={confirmationResult} name={name}/>
                            : 
                            <Box>
                                <Input placeholder="Name" 
                                    value={name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setName(event.target.value);
                                }}/>
                                {nameError ? <Text textColor={'red'}>{nameError}</Text> : <Box />}
                                <Input marginTop={'1rem'} 
                                    placeholder="Phone Number" 
                                    type={'number'}
                                    value={phone}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setPhone(event.target.value);
                                }}/>
                                {phoneError ? <Text textColor={'red'}>{phoneError}</Text> : <Box />}
                                <Text marginTop={'1rem'} fontStyle={'italic'} fontSize={'0.8rem'}>*By signing up, you are agreeing to our <a onClick={() => {
                                    navigator('/termsandconditions');
                                    onClose();
                                }} style={{ color: 'lightBlue', cursor: 'pointer'}}><u>Terms and Conditions</u></a> and <a onClick={() => {
                                    navigator('/privacypolicy');
                                    onClose();
                                }} style={{ color: 'lightBlue', cursor: 'pointer'}}><u>Privacy Policy</u></a>.</Text>
                                <Button id='sign-up' isLoading={isLoading} marginTop={'1rem'} onClick={() => {_signUp()}}>Sign Up!</Button>
                            </Box>
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}