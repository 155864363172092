import { DeweyUser } from "../models/DeweyUser";
import { doc, query, getDocs, collection, where } from 'firebase/firestore';
import { db } from "../firebaseConfig";

export default async function getUserDoc(phone: string): Promise<DeweyUser | null> {
    if(!phone.startsWith('+1')) {
        if(phone.length === 10) {
            phone = `+1${phone}`
        } else {
            phone = `+${phone}`
        }
    }
    console.log(`checking for user with phone: ${phone}`)
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where("phone", "==", phone));
    const user: DeweyUser | null = await getDocs(q).then((querySnap) => {
        if(querySnap.docs.length > 0) {
            const doc = querySnap.docs[0]
            return {
                id: doc.id,
                phone: doc.data().phone,
                name: doc.data().name,
                lastReceivedFromUser: doc.data().lastReceivedFromUser?.toDate(),
                lastSentToUser: doc.data().lastSentToUser?.toDate(),
                lastReminded: doc.data().lastReminded?.toDate(),
                hourCadence: doc.data().hourCadence,
                tzOffset: doc.data().tzOffset,
                wakeUpTime: doc.data().wakeUpTime?.toDate(),
                bedtime: doc.data().bedtime?.toDate(),
                sendOnWeekends: doc.data().sendOnWeekends,
            } as DeweyUser
        } else {
            return null;
        }
    });
    return user;
}