import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { firebaseUserState } from "../atoms/firebaseUserState";
import { auth } from "../firebaseConfig";
import useUserData from "../hooks/useUserData";

export default function Dashboard() {
    const [firebaseUser, setFirebaseUser] = useRecoilState(firebaseUserState)
    const userData = useUserData(firebaseUser);
    const navigator = useNavigate();

    const logout = async () => {
        await signOut(auth)
    }

    React.useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          setFirebaseUser(user)
          console.log(`User: ${user}`)
          if(user) {
            navigator('/dashboard')
          } else {
            navigator('/')
          }
        })
    
        return () => unsubscribe()
      }, [])
    return (
        <Box bgColor={'#1a202c'} minHeight={'100vh'}>
            <Heading paddingTop={'2rem'} size={'md'}>Hi, {userData?.name}</Heading>
            <Text>{firebaseUser?.phoneNumber}</Text>
            <Text>Current Wake-Up Time: { userData?.wakeUpTime?.toLocaleString() }</Text>
            <Text>Current Bedtime: { userData?.bedtime?.toLocaleString() }</Text>
            <Text>Reminder Cadence: {userData?.hourCadence} hours</Text>
        </Box>
    )
}