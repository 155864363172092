import { Text, Box, Flex, VStack, Center, Heading, Image } from "@chakra-ui/react";
import SignUp from "../components/SignUp";
import checklistPhoneImg from '../assets/images/checklist_phone.png';
import messageAlertImg from '../assets/images/iphone_message_alert.png';
import flameStreakImg from '../assets/images/flame-streak.png';
import questionMarkImg from '../assets/images/questionmark.png';
import deweyImg from '../assets/images/Robot_Final.png';
import FeatureBlock from "../components/FeatureBlock";
import FAQ from "../components/FAQ";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseConfig";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { firebaseUserState } from "../atoms/firebaseUserState";
import Learning from "../components/Learning";

export default function Home() {
    const [firebaseUser, setFirebaseUser] = useRecoilState(firebaseUserState)
    const navigator = useNavigate();

    React.useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          setFirebaseUser(user)
          console.log(`User: ${user}`)
          if(user) {
            navigator('/dashboard')
          } else {
            navigator('/')
          }
        })
    
        return () => unsubscribe()
      }, [])
      
    return (
        <VStack>
            <VStack height={'100vh'} 
                width={'100vw'} 
                spacing={[4,8]}
                background={'linear-gradient(to bottom right, #000000 50%, transparent 50.5%) no-repeat bottom/100% 75%, linear-gradient(0deg, #000000, #000000) no-repeat top/100% 25%'}
                background-size={'100% 40%, 100% 40%'}>
                <Flex width={'100%'} height={'100%'} flexDir={['column', 'row']}>
                    <Flex flex={1} display={{ base: 'flex', md: 'none'}} justifyContent={'center'}>
                        <Center>
                            <Image src={deweyImg} boxSize={'70%'} paddingRight={['0', '4rem']} alignSelf={'center'}/>
                        </Center>
                    </Flex>
                    <Flex flex={1} justifyContent={'center'}>
                        <Center>
                            <Box textAlign={'start'} width={['80vw','35vw']}>
                                <Heading>Do More with <span style={{backgroundColor: 'white', color: 'black', paddingLeft: '0.25rem', paddingRight: '0.25rem'}}>Dewey</span></Heading>
                                <Text marginTop={'1rem'} marginBottom={'1rem'}>Get reminders and stay motivated with text messages from Dewey, your AI accountability buddy.</Text>
                                <SignUp big={true} buttonText={"Meet Dewey!"} headerText={'Get Dewey!'} position={"home-main-cta"} />
                            </Box>
                        </Center>
                    </Flex>
                    <Flex flex={1} display={{ base: 'none', md: 'flex'}} justifyContent={'center'}>
                        <Center>
                            <Image src={deweyImg} boxSize={'80%'} paddingRight={'4rem'} />
                        </Center>
                    </Flex>
                </Flex>
            </VStack>
            <VStack>
                <Box width={['100%', '100%', '80%']} padding={'1rem'} marginBottom={'2rem'}>
                    <Heading>Get back on track with a friendly AI accountability buddy.</Heading>
                    <Text>Dewey is here to help you stay organized and productive. With text message reminders and goal tracking, it's like having an AI accountability buddy in your pocket.</Text>
                </Box>
                <FeatureBlock side='text-right' 
                    image={checklistPhoneImg}
                    heading={'Get on top of your to-do list with a conversational AI.'}
                    text={'Don’t let your to-do list grow out of control. With Dewey, you can converse with your to-do list and be held accountable for getting things done. Get on top of your tasks without the hassle, and get more done in less time.'} />
                <FeatureBlock side='text-left' 
                    image={messageAlertImg}
                    heading={'Stay on track with personalized reminders.'}
                    text={"Get personalized reminders of what you need to do and a few nudges throughout the day - so you can stay on top of your tasks and build new habits. With Dewey, your AI accountability buddy, it's never been easier to get things done. Do More with Dewey today!"} />
                <FeatureBlock side='text-right' 
                    image={flameStreakImg}
                    heading={'Make habits stick with Dewey.'}
                    text={"Habits are hard to break and even harder to make. But with Dewey, you can actually do both. Get check-ins throughout the day to see how you're doing and make sure your new habits stick! Make more happen with Dewey today."} />
                <FeatureBlock side='text-left' 
                    image={questionMarkImg}
                    heading={'Get the answers you need from your AI accountability buddy.'}
                    text={"Don’t waste time Googling for answers—get them from your own personal AI accountability buddy. Dewey uses SMS text messages to keep your to-do list in check, and can even answer many of the questions that you'd usually Google for!"} />
                <Box bgColor="black" padding={['4rem 2rem 2rem 2rem', '4rem 2rem 2rem 2rem', '4rem 2rem 2rem 2rem', '4rem 8rem 2rem 8rem']} width={'100vw'}>
                    <Center>
                        <Learning />
                    </Center>
                    <FAQ />
                    <Box height={'2rem'} />
                    <SignUp big={true} buttonText={"Let Me Try Dewey!"} headerText={'Get Dewey!'} position={'home-bottom'} />
                    <Text marginTop={'4rem'} marginBottom={'2rem'} fontSize={'0.65rem'}>Created by Kyle Banta<br/>Contact Me @ kbantadevelopment@gmail.com</Text>
                </Box>
            </VStack>
        </VStack>
      )
}

function setFirebaseUser(user: import("@firebase/auth").User | null) {
    throw new Error("Function not implemented.");
}
