import { Box, Text, Heading, Center } from "@chakra-ui/react";

export default function Upgrade() {
    return (
        <Box height={'100vh'} bgColor={'#1a202c'}>
            <Center flexDir={'column'} padding={'2rem'}>
                <Heading >Best Friends Coming Soon!</Heading>
                <Text marginTop={'2rem'}>Dewey's not yet ready to be best friends with anyone yet, but the upgraded plan is coming soon! Check back later.</Text>
            </Center>
        </Box>
    )
}