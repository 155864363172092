import { Box, Heading, Text, Center } from "@chakra-ui/react";
import { useEffect } from "react";



export default function PrivacyPolicy() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    return (
        <Center flexDir={'column'}>
            <Box width={['90vw', '90vw', '80vw', '60vw']} textAlign={'left'} alignSelf={'center'}>
                <Heading marginTop={'2rem'}>Privacy Policy</Heading>
                {
                //<div name="termly-embed" data-id="f18d9533-4ca1-496d-93f8-99fad02ad7e3" data-type="iframe"></div>
                }
                <a href={"https://app.termly.io/document/privacy-policy/f18d9533-4ca1-496d-93f8-99fad02ad7e3"} target={"_blank"} style={{color: 'lightblue'}}><u>Click here to view Privacy Policy</u></a>
            </Box>
            <Text marginTop={'4rem'} marginBottom={'2rem'} fontSize={'0.65rem'}>Created by Kyle Banta<br/>Contact Me @ kbantadevelopment@gmail.com</Text>
        </Center>
    )
}