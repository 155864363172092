// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
console.log(`${process.env.NODE_ENV} - ${process.env.REACT_APP_FIREBASE_API_KEY}`)
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "dewey-2acef.firebaseapp.com",
  projectId: "dewey-2acef",
  storageBucket: "dewey-2acef.appspot.com",
  messagingSenderId: "449736334327",
  appId: "1:449736334327:web:e18b54a018bb764601acf2",
  measurementId: "G-F9WRDZ6Z7F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);