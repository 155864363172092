import { Box, Heading, Text, Center } from "@chakra-ui/react";
import SignUp from "../components/SignUp";

export default function About() {
    return (
        <Center flexDir={'column'}>
            <Box width={['90vw', '90vw', '80vw', '60vw']} textAlign={'left'} alignSelf={'center'}>
                <Heading marginTop={'2rem'}>About Dewey</Heading>
                <Text marginTop={'2rem'} marginBottom={'2rem'}>Welcome to Dewey, your personal AI accountability buddy!<br/><br/>At Dewey, our mission is to help you form new habits, break old ones, and stay on top of your to-do list. We know how easy it is to lose motivation or forget about important tasks, and that's where Dewey comes in.<br/><br/>Our AI-powered assistant uses text message reminders to nudge you along throughout the day, keeping you on track and helping you achieve your goals. Whether you're looking to exercise more, eat healthier, or simply get more done, Dewey is here to support you every step of the way.<br/><br/>But Dewey isn't just a task manager – it's also a friend. With our conversational AI and GPT-3 technology, Dewey can answer simple questions and provide helpful information on a wide range of topics. Need a recipe for dinner? Want to learn more about a historical event? Just ask Dewey, and it will provide the answers you need.<br/><br/>Dewey was created by Kyle Banta, who was looking for a way to become more disciplined and form better habits. After experimenting with various tools and techniques, Kyle discovered that a simple to-do list wasn't enough – he needed a more personal and engaging way to stay on track. That's why he created Dewey, a conversational AI assistant that acts as a accountability buddy and provides the support and encouragement you need to achieve your goals.<br/><br/>So why wait? Sign up for Dewey today and start making progress towards your goals. With our customizable reminders and helpful information, you'll be amazed at how much you can accomplish with a little help from your AI friend.</Text>
                <SignUp big={true} buttonText={'Meet Dewey!'} headerText={"Meet Dewey Now!"} position={'about'} />
            </Box>
            <Text marginTop={'4rem'} marginBottom={'2rem'} fontSize={'0.65rem'}>Created by Kyle Banta<br/>Contact Me @ kbantadevelopment@gmail.com</Text>
        </Center>
    )
}