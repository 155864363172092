import { Box, Flex, Heading, Text, Image } from "@chakra-ui/react";

export default function FeatureBlock(props: any) {

    return (
        <Box paddingTop={'2rem'} paddingBottom={'2rem'}>
            <Flex width={['100vw', '100vw', '80vw','60vw']}>
                {
                    props.side === 'text-right' ?
                <Box marginLeft={'1rem'}>
                    {
                        props.image ? <Image src={props.image} boxSize={['200px', '300px']} /> : <Box bgColor={'gray.200'} />
                    }
                </Box> : <Box />
                }
                <Box width={'70%'} textAlign={'left'} padding={'1rem 2rem'}>
                    <Heading>{props.heading}</Heading>
                    <Text>{props.text}</Text>
                </Box>
                {
                    props.side !== 'text-right' ?
                <Box marginRight={'1rem'}>
                    {
                        props.image ? <Image src={props.image} boxSize={['200px', '300px']} /> : <Box bgColor={'gray.200'} />
                    }
                </Box> : <Box />
                }
            </Flex>
        </Box>
    )
}