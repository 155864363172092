import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firebaseConfig";

export default async function signUp(name: string, phone: string) {
    let confirmationResult;
    window.recaptchaVerifier = new RecaptchaVerifier('sign-up', {
        'size': 'invisible',
        'callback': async (response: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        }
      }, auth);
    //check if phone starts with '1', or prepend it
    if(Array.from(phone)[0] !== '1') {
        phone = `1${phone}`
    }
    //useRecoilCallback();
    confirmationResult = await signInWithPhoneNumber(auth, `+${phone}`, window.recaptchaVerifier);
    console.log(`Confirmation Result: ${JSON.stringify(confirmationResult)}`)
    return confirmationResult
}