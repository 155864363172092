import {
  ChakraProvider,
  Box,
  theme,
  extendTheme,
  ThemeExtension
} from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import Dashboard from "./pages/Dashboard"
import NavBar from "./components/NavBar"
import About from "./pages/About"
import Upgrade from "./pages/Upgrade"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import TermsAndConditions from "./pages/TermsAndConditions"

export const App = () => {

  const newTheme = extendTheme({
    useSystemColorMode: false,
    initialColorMode: "dark",
    cssVarPrefix: "chakra"
  });
  return (
  <ChakraProvider theme={newTheme}>
    <Box textColor={'white'} bgColor={'#1a202c'} textAlign="center" fontSize="xl">
      <NavBar />
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/dashboard"
                        element={<Dashboard />} />
          <Route path="/upgrade" element={<Upgrade />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
      </Routes>
    </Box>
  </ChakraProvider>
)
}
