import { Box, Button, Flex, Heading, IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from "@chakra-ui/react";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Login from "./Login";
import SignUp from "./SignUp";
import { auth } from "../firebaseConfig";
import { signOut } from "firebase/auth";
import { firebaseUserState } from "../atoms/firebaseUserState";
import { useRecoilState } from "recoil";

export default function NavBar() {
    const navigator = useNavigate();
    const [firebaseUser, setFirebaseUser] = useRecoilState(firebaseUserState);

    const logout = async () => {
        await signOut(auth)
    }
    return (
        <Flex padding={'1rem'} bgColor={'black'} justifyContent={'space-between'} width={'100%'}>
            <Heading>Dewey.</Heading>
            <Box>
                <Box display={{ base: 'flex', md: 'none'}} bgColor={'#1a202c'}>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            bgColor={'blackAlpha.400'}
                            aria-label='Options'
                            icon={<FaBars />}
                            variant='solid'
                        />
                        <MenuList bgColor={'#1a202c'}>
                            <MenuItem bgColor={'#1a202c'} onClick={() => navigator('/')}>
                            Home
                            </MenuItem>
                            <MenuItem bgColor={'#1a202c'} onClick={() => navigator('/about')}>
                            About
                            </MenuItem>
                            {
                                !firebaseUser ? 
                                <MenuItem bgColor={'#1a202c'}>
                                    <Login menu={true}/>
                                </MenuItem>
                                : <Box />
                            }
                            {
                                !firebaseUser ? 
                                    <MenuItem bgColor={'#1a202c'}>
                                        <SignUp menu={true} buttonText={'Get Dewey!'} headerText={'Sign Up for Dewey!'} position={'nav-bar-mobile'} />
                                    </MenuItem>
                                : <Box />
                            }
                            {
                                firebaseUser ?
                                <MenuItem bgColor={'#1a202c'} onClick={logout}>
                                    Sign Out
                                </MenuItem>
                                : <Box />
                            }
                            
                        </MenuList>
                    </Menu>
                </Box>
                <Flex display={{ base: 'none', md: 'flex'}}>
                    <Button marginRight={'0.5rem'} variant={'ghost'}  onClick={() => navigator('/')}><span style={{textUnderlineOffset: 5, textDecoration: 'underline'}}>Home</span></Button>
                    <Button marginRight={'0.5rem'} variant={'ghost'}  onClick={() => navigator('/about')}><span style={{textUnderlineOffset: 5, textDecoration: 'underline'}}>About</span></Button>
                    { !firebaseUser ? <Login menu={true}/> : <Box /> }
                    { !firebaseUser ? <SignUp buttonText={'Sign Up'} menu={true} headerText={'Get Dewey!'} position={'nav-bar-desktop'} /> : <Box /> }
                    { firebaseUser ? <Button onClick={logout} color={'black'}>Sign Out</Button> : <Box />}
                </Flex>
            </Box>
        </Flex>
    );
}